
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Facturas</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Listado Facturas</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input type="text" class="form-control ps-5 radius-30" placeholder="Buscar Factura"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
						<div class="ms-auto"><a href="javascript:;" @click="llenarFecha();" data-bs-toggle="modal" data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear Factura</a></div>
						</div>
                        <!-- Button trigger modal -->
						<!-- Modal -->
						<div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Crear Factura</h5>
										<button type="button" class="btn-close" id="close_categoria" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Fecha y Hora Emisión</h6>
											</div>
											<div class="col-sm-9">
												<Datepicker
													v-model="factura.fecha_hora_emision"
													dark placeholder="Selecciona una Fecha"
													autoApply
													:dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
													:enableTimePicker="false"
													monthNameFormat="long"
													:format="format"
													modelType="dd-MM-yyyy"
													locale="es">
												</Datepicker>
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">NIT</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" autofocus id="id_receptor" v-model="factura.id_receptor" class="form-control"   />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" autofocus id="nombre_receptor" v-model="factura.nombre_receptor" class="form-control"   />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Dirección</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" autofocus id="direccion" v-model="factura.direccion" class="form-control"   />
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="crearFactura()" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal -->
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>#</h6></th>
										<th><h6>Serie</h6></th>
										<th><h6>DTE</h6></th>
										<th><h6>NIT</h6></th>
										<th><h6>Nombre</h6></th>
										<th><h6>Fecha y Hora</h6></th>
										<th><h6>Fecha y Hora Certificación</h6></th>
										<th><h6>Total</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Venta#</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in facturasData.results" :key="item.id">
										<td><h6>{{item.numero}}</h6></td>
										<td><h6>{{item.serie}}</h6></td>
										<td><h6>{{item.dte}}</h6></td>
										<td><h6>{{item.id_receptor}}</h6></td>
										<td><h6>{{item.nombre_receptor}}</h6></td>
										<td><h6>{{item.fecha_hora_emision}}</h6></td>
										<td><h6>{{item.fecha_hora_certificacion}}</h6></td>
										<td><h6>{{item.gran_total}}</h6></td>
										<td>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.estado]] == '9'" class="btn-danger"> <i  class='bx bx-minus me-1'></i>Anulada</div>
                                                <div v-if="[[item.estado]] == 'A'" class="btn-warning"> <i  class='bx bx-circle me-1'></i>No Certificada</div>
                                                <div v-if="[[item.estado]] == 'C'" class="btn-success"> <i  class='bx bx-circle me-1'></i>Certificada</div>
                                            </div>
                                        </td>
										<td>
											<h6>{{item.numero_venta}}</h6>
										</td>
										<td>
											<div class="d-flex order-actions">
												<a :href="this.URL_TENANT+'/venta/facturas/detalle_factura/'+item.id" class=""><i class='bx bxs-edit'></i></a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>


			</div>
		</div>
</template>
<style>
</style>
<script>
import { ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';

import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
//import VueNextSelect from 'vue-next-select'
export default {
    name: 'facturas_component',
    components:{
		Datepicker,
    },
setup() {
	const date = ref(new Date());
		function formatDate(userDate) {
		return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
		}
		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${day}-${month}-${year}`;
		}
		const startTime = ref({ hours: 0, minutes: 0 });
		const first_time = ref({
			hours: new Date().getHours()+1,
			minutes: 0
		});
		const last_time = ref({
			hours: new Date().getHours()+2,
			minutes: 0
		});
		var tzoffset = (new Date()).getTimezoneOffset() * 60000; 
		var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
		var today_date = formatDate(today_is);
		//
		return {
			date,
			format,
			first_time,
			last_time,
			startTime,
			today_date,
		}
},
data() {
	return{
        facturasData: [],
		factura_datos: {},
        nameError: [],
        URL_TENANT: '',
		factura: {
			tipo: 'FACT',
			fecha_hora_emision: '',
			id_receptor: 'CF',
			nombre_receptor: '',
			direccion: '',
			nombre_corto: 'IVA' //MODIFICAR LUEGO AL CAMBIAR ENTIDADES.
		},
		clientesData: [0],
		condicion_nit: 0,
		cliente: null,
		id_factura: null,


		}
	},

computed: {
},
watch:{
        factura: {
            handler: function () {
                console.log("BUSQUEDA IMPLACABLE");
                if (this.factura.id_receptor != ''){
                    console.log("BUSQUEDA IMPLACABLE222");
                    this.clienteEncontrado = this.clientesData.find(element => element.nit === this.factura.id_receptor);
                    console.log(this.clienteEncontrado);
                    if (this.clienteEncontrado){
                        this.condicion_nit = 1;
                        this.cliente = this.clienteEncontrado;
                        this.factura.nombre_receptor = this.clienteEncontrado.nombre + ' ' + this.clienteEncontrado.apellido;
                        this.factura.direccion = this.clienteEncontrado.direccion;
                    }
                    if (!this.clienteEncontrado && this.condicion_nit==1){
                        this.cliente = null;
                        this.factura.nombre_receptor = '';
                        this.factura.direccion = '';
                        this.condicion_nit = 0;
                    }
					
                }
				
                
            },
            deep: true,
        },
        cliente: function () {
            if(this.cliente){
                notificaciones.round_info_noti($.msg='Se encontró al Cliente: '+this.clienteEncontrado.nombre + ' ' + this.clienteEncontrado.apellido);
            }
        },
    },
methods: {
	// Validadores:
    validateInputs(){
        this.nameError =  (!this.factura.id_receptor) ? ['El NIT es requerido'] : [];
        this.nameError = (!this.factura.nombre_receptor) ? [...this.nameError, 'El Nombre es Requerido'] : this.nameError;
        this.nameError = (!this.factura.fecha_hora_emision) ? [...this.nameError, 'La Fecha es Requerida'] : this.nameError;
        this.nameError = (!this.factura.direccion) ? [...this.nameError, 'La Dirección es Requerida'] : this.nameError;

    },
	async crearFactura(){
		this.validateInputs();
        if(this.nameError.length != 0){
            console.log("NAME ERROR");
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
            try{
                const formData = new FormData();
				console.log(this.factura.fecha_hora_emision)
				const fechaOriginal = this.factura.fecha_hora_emision;
				const ahora = new Date();
				const horas = ahora.getHours().toString().padStart(2, '0');
				const minutos = ahora.getMinutes().toString().padStart(2, '0');
				const fechaHora = `${fechaOriginal} ${horas}:${minutos}`;
                formData.append("fecha_hora_emision", fechaHora);
				formData.append("tipo", "FACT");
                formData.append("id_receptor", this.factura.id_receptor);
                formData.append("nombre_receptor", this.factura.nombre_receptor);
                formData.append("direccion", this.factura.direccion);
                formData.append("codigo_postal", 0);
                formData.append("municipio", this.factura_datos.municipio);
                formData.append("departamento", this.factura_datos.departamento);
                formData.append("pais", this.factura_datos.pais);
                formData.append("tipo_frase", this.factura_datos.tipo_frase);
                formData.append("codigo_escenario", this.factura_datos.codigo_escenario);
                formData.append("nombre_corto", "IVA"); //Modificar luego al cambiar entidades
                const response = await fetch(this.URL_TENANT+'/api/factura/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                if(response.ok){
                    const data = await response.json();
                    this.id_factura = data.id;
					window.location.href = this.URL_TENANT+'/venta/facturas/detalle_factura/'+this.id_factura;

                }
			}catch(error){
				console.log(error);
				notificaciones.round_error_noti($.msg='Error, algo salió mal');
			}
		}
	},
	async getClientesData() {
            const response = await fetch(this.URL_TENANT+'/api/cliente_nit/');
            this.clientesData = await response.json();
        },
    async llenarFecha(){
            this.factura = {
                fecha_hora_emision: this.today_date,
            }
        },
	async getFacturaDatos() {
            const response = await fetch(this.URL_TENANT+'/api/factura_datos/1/');
            this.factura_datos = await response.json();
        },

	async getFacturasData() {
        const response = await fetch(this.URL_TENANT+'/api/factura/');
        this.facturasData = await response.json();
    },
    },
created() {
      // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getFacturasData();
	this.getFacturaDatos();
	this.getClientesData();
	},
}
</script>
