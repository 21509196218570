<template>
<div class="page-wrapper">
            <div class="page-content">
                <!--breadcrumb-->
                <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div class="breadcrumb-title pe-3">Factura</div>
                    <div class="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-money'></i></a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Detalle de Factura</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="ms-auto">
						<div class="btn-group">
							<a href="/venta/facturas/" type="button" class="btn btn-light">Regresar a Facturas</a>
						</div>
					</div>
                </div>
                <!--end breadcrumb-->
                    <div class="main-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <div class="mt-3">
                                                <h4># Factura: {{ facturaData.numero }}</h4>
                                                <h4>Total: {{  $filters.currencyGTQ(facturaData.gran_total) }}</h4>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <ul class="list-group list-group-flush" >
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-id-card">&nbsp;</i>Nit</h6>
                                                <span class="text-white">{{ facturaData.id_receptor }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-user">&nbsp;</i>Nombre</h6>
                                                <span class="text-white">{{ facturaData.nombre_receptor }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-map">&nbsp;</i>Dirección</h6>
                                                <span class="text-white">{{ facturaData.direccion }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-radio-circle">&nbsp;</i>Estado</h6>
                                                <span v-if="facturaData.estado == 'A'" class="text-white">{{ facturaData.nombre_estado }}</span>
                                                <span v-if="facturaData.estado == '9'" style="color: red;">{{ facturaData.nombre_estado }}</span>
                                                <span v-if="facturaData.estado == 'C'" style="color: blue;">{{ facturaData.nombre_estado }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-calendar">&nbsp;</i>Fecha y Hora Emisión</h6>
                                                <span class="text-white">{{ facturaData.fecha_hora_emision }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-user-pin">&nbsp;</i>Usuario</h6>
                                                <span class="text-white">{{ facturaData.nombre_usuario }}</span>
                                            </li>
                                        </ul>
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <div class="mt-3">
                                                <button type="button" ref="finalizarFacturaBtn" :disabled="!detalleFacturaData.length > 0 || facturaData.estado =='C' || facturaData.estado == '9'" @click="getUserData(); getProductosBusquedaData()" data-bs-toggle="modal" data-bs-target="#finalizarDetalleFactura" class="btn btn-success px-5">Certificar Factura (F12)</button>
                                                <!-- Modal Finalizar Factura -->
                                                <div class="modal fade"  id="finalizarDetalleFactura" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Certificar Factura</h5>
                                                                <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">NIT</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input ref="inputInModal" type="text" id="nit"  class="form-control" v-model="facturaData.id_receptor"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Nombre</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="nombre"  class="form-control" v-model="facturaData.nombre_receptor"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Dirección</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="direccion"  class="form-control" v-model="facturaData.direccion"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Total</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control"  :value=" $filters.currencyGTQ(facturaData.gran_total)"  />
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" @click="finalizarFactura();"  :disabled="facturando" class="btn btn-success px-5">
                                                                    <loading v-if="facturando"></loading>
                                                                    Certificar
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal Finalizar Venta -->
                                            </div>
                                            
                                            <div class="mt-3">
                                                <button type="button" data-bs-toggle="modal" :disabled="facturaData.estado !='C'" data-bs-target="#anularFactura" class="btn btn-danger px-5">Anular Factura</button>
                                                <!-- Modal Anular Venta -->
                                                <div class="modal fade"  id="anularFactura" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Anular Factura</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <h5 class="mb-1">¿Desea Anular la Factura {{ facturaData.numero }}?</h5>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Motivo:</h6>
                                                                    </div>
                                                                    <div class="col-sm-9">
                                                                        <input ref="inputInModal" type="text" id="nit" class="form-control" v-model="anular_factura.motivo"  />
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" @click="anularFactura();"  class="btn btn-danger px-5">
                                                                    <loading v-if="anulando"></loading>
                                                                    Anular Factura
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal Anular Venta -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="card">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-lg-9">
                                                <h3>Detalle de Factura</h3>
                                            </div>
                                            <div class="col-lg-3">
                                                <button type="button" v-if="facturaData.estado == 'A'" ref="buscarProductoBtn" data-bs-toggle="modal" @click="getProductosBusquedaData()" data-bs-target="#agregarDetalleModal" class="btn btn-light">Buscar Producto (F1)</button>
                                                <!-- Modal -->
                                                <div class="modal fade" id="agregarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Añadir Producto a la Factura</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="col-12">
                                                                    <div class="position-relative">
                                                                        <input ref="busquedaPorTexto" type="text" class="form-control ps-5" v-model="busqueda" autofocus  placeholder="Buscar Producto...(Por Nombre o Código de Barra)"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                                                    </div>
                                                                </div>
                                                                <hr/>
                                                                <table class="table mb-0">
                                                                    <thead class="table-light">
                                                                        <tr>
                                                                            <th><h6>Código</h6></th>
                                                                            <th><h6>Código de Barra</h6></th>
                                                                            <th><h6>Producto</h6></th>
                                                                            <th><h6>Unidades de Medida</h6></th>
                                                                            <th><h6>Seleccionar</h6></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="itemProd in productoDataFilter" :key="itemProd.id">
                                                                            <td><h6>{{ itemProd.codigo }}</h6></td>
                                                                            <td><h6>
                                                                                <div v-for='cb in itemProd.codigos_barra_producto' :key="cb.id" class="col">
                                                                                    <ul>
                                                                                        <li>
                                                                                        {{cb.codigo_barra}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <label>{{itemProd.nombre}}</label></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
                                                                                    <ul>
                                                                                        <li>
                                                                                        {{um.nombre_unidad_medida}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <button type="button" data-bs-dismiss="modal"  @click="buscarProductoPorBarra(itemProd.id, 'id')"  class="btn btn-light">Seleccionar</button>
                                                                            </h6></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="modal-footer">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal -->
                                            </div><!-- End Col-2 -->
                                        </div><!-- End Row -->
                                        <div class="row">
                                            <hr/>
                                        </div>
                                        <div class="row" v-if="facturaData.estado == 'A'">
                                            <div class="col-lg-6">
                                                <input type="text"  v-model="barraBusqueda" autofocus class="form-control ps-5" @keyup.enter="buscarProductoPorBarra(barraBusqueda, 'barra');" placeholder="Código del Producto | Código de Barra">
                                            </div>
                                            <div class="col-lg-6">
                                                <button type="button" @click="buscarProductoPorBarra(barraBusqueda, 'barra')" class="btn btn-light">Ir</button>
                                            </div>
                                        </div>
                                        <div class="form-body mt-4">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="border border-3 p-4 rounded">
                                                        <div class="modal-body">
                                                            <table class="table mb-0">
                                                                <thead class="table-light">
                                                                    <tr>
                                                                        <th><h6>#No</h6></th>
                                                                        <th><h6>B/S</h6></th>
                                                                        <th><h6>Cantidad</h6></th>
                                                                        <th><h6>Descripción</h6></th>
                                                                        <th><h6>Precio/Valor Unitario</h6></th>
                                                                        <th><h6>Descuentos</h6></th>
                                                                        <th><h6>Total</h6></th>
                                                                        <th><h6>Impuestos</h6></th>
                                                                        <th><h6>Acciones</h6></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="item in detalleFacturaData" :key="item.id">
                                                                        <td><h6>{{ item.numero_linea }}</h6></td>
                                                                        <td>
                                                                            <h6 v-if="item.bien_servicio == 'B'">Bien</h6>
                                                                            <h6 v-if="item.bien_servicio == 'S'">Servicio</h6>
                                                                        </td>
                                                                        <td><h6>{{ item.cantidad }}</h6></td>
                                                                        <td><h6>{{ item.descripcion_producto }}</h6></td>
                                                                        <td><h6>Q {{ item.precio_unitario }}</h6></td>
                                                                        <td><h6>Q {{ item.descuento }}</h6></td>
                                                                        <td><h6>Q {{ item.total }}</h6></td>
                                                                        <td><h6>Q {{ item.monto_impuesto   }}</h6></td>
                                                                        <td><h6>
                                                                            <div class="d-flex order-actions">
                                                                                <a v-if="facturaData.estado != 'C'" href="javascript:;"  data-bs-target="#eliminarDetalleModal" @click="productoEliminar(item.id, item.descripcion_producto)" data-bs-toggle="modal" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                            </div></h6>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!--end row-->
                                        </div>
                                    </div><!-- End Card Body -->
                                </div><!-- End Card -->
                            </div><!-- End Col-8 -->
                        </div><!-- End Row -->
                    </div>
                </div>
            </div>
<!-- Modal Agregar Producto-->
<div class="modal fade" id="modalAgregarProducto" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content bg-dark">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Producto</h1>
            <button type="button" class="btn-close" id="agregar_detalle_close2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Código</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text"  id="producto"  class="form-control" v-model="productoEncontrado.codigo"  readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Producto</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text"  id="producto"  class="form-control" v-model="productoEncontrado.nombre"  readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Unidad Medida</h6>
                </div>
                <div class="col-sm-9">
                    <vue-select  :options="productoEncontrado.unidades_medida"
                        @blur="removeObjectArray(productoEncontrado, 'unidad_medida')"
                        v-model="productoEncontrado.unidad_medida"
                        label-by="nombre_unidad_medida"
                        track-by="nombre_unidad_medida"
                        value-by="id"
                        search-placeholder="Selecciona una Unidad de Medida"
                        multiple
                        collapse-tags="true"
                        taggable
                        searchable
                        placeholder="Selecciona una Unidad de Medida"
                        close-on-select>
                        <template #label="{ selected }">
                        <template v-if="selected">
                            <div style="color:white;">{{ selected.nombre_unidad_medida }} </div>
                        </template>
                        <template v-else > <div style="color:white;">Seleccione una Unidad de Medida</div></template>
                        </template>
                        <template #dropdown-item="{ option }">
                        <div style="color:white;">{{ option.nombre_unidad_medida }}</div>
                        </template>
                    </vue-select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Cant Unidad Medida</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text"  id="cantidad"  class="form-control" v-model="productoEncontrado.cantidad_unidad_medida_select" readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Precio Unidad Medida</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text" id="precio"  class="form-control" v-model="productoEncontrado.precio" readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Cant a Facturar</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text"  id="cantidad"  class="form-control" v-model="productoEncontrado.cantidad_facturar"  @input="calcularConCantidad()" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Unidades Totales</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text" id="precio"  class="form-control" v-model="productoEncontrado.cantidad_totales" readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Precio Unitario</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text" id="precio"  class="form-control" v-model="productoEncontrado.precio_facturar" @input="calcularConPrecio()" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3">
                    <h6 class="mb-0">Total Línea</h6>
                </div>
                <div class="col-sm-9">
                    <input type="text" id="precio"  class="form-control" v-model="productoEncontrado.total_facturar" @input="calcularConTotal()" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary"  @click="agregarProductoDetalle();">Agregar</button>
        </div>
        </div>
    </div>
</div>
<!-- Fin Modal Agregar Producto -->
    <!-- Modal Eliminar Producto -->
<div class="modal fade"  id="eliminarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Eliminar Producto</h5>
                <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Producto</h6>
                    </div>
                    <div class="col-sm-10">
                        <input class="form-control" readonly v-model="productoDetalleEliminar.nombre" type="text" >
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" @click="eliminarDetalleFactura()" data-bs-dismiss="modal" class="btn btn-danger">Eliminar</button>
            </div>
        </div>
    </div>
    </div>
    <!-- End Modal Eliminar Producto -->
</template>

<script>
import  Cookies from 'js-cookie';
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
//import * as mykey from '../assets/js/mykey.js'
import { Modal } from 'bootstrap';
import VueNextSelect from 'vue-next-select';
import loading from "../utils/loading.vue"

console.log(Cookies.get());
export default {
    name: 'DetalleFacturaComponent',
    components:{
        'vue-select': VueNextSelect,
        loading,
        },
data() {
	return{
        empresa: {},
        id_factura: '',
		facturaData: [],
        estaFinalizandoFactura: false,
        cantidad_recibida: '',
        productoDetalleEliminar: {
            id: '',
            nombre: ''
        },
        cambio: '',
        barraBusqueda: '',
        busqueda: '',
        id: '',
        userData: {},
		plazosData: {id: ''},
        nameError: [],
        plazoCredito: [],
        detalleFacturaData: [],
        productoDetalleModificar: {
            id: '',
            cantidad: '',
        },
        anular_factura: {
            motivo: ''
        },
        facturando: false,
        anulando: false,
        nit_factura: '',
        clientesData: [0],
		cliente: null,
        productosBusqueda: [],
        productoEncontrado: {
            codigo: '',
            cantidad: '',
            precio: '',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
            inventario: '',
            cantidad_unidad_medida_select: '',
            cantidad_facturar: '',
            precio_facturar: '',
            total_facturar: '',
            cantidad_totales: ''
        },
        productoSeleccionado: '',
		URL_TENANT: '',
        mostrarModalNuevo: false,
		}
	},
watch:{
    productoSeleccionado: {
            handler: function () {
                if(this.productoSeleccionado.length > 1){
                    this.productoSeleccionado.shift();
                }
            },
            deep: true,
        },
        facturaData: {
            handler: function () {
                console.log("BUSQUEDA IMPLACABLE");
                if (this.facturaData.id_receptor != ''){
                    console.log("BUSQUEDA IMPLACABLE222");
                    this.clienteEncontrado = this.clientesData.find(element => element.nit === this.facturaData.id_receptor);
                    console.log(this.clienteEncontrado);
                    if (this.clienteEncontrado){
                        this.condicion_nit = 1;
                        this.cliente = this.clienteEncontrado;
                        this.facturaData.nombre_receptor = this.clienteEncontrado.nombre + ' ' + this.clienteEncontrado.apellido;
                        this.facturaData.direccion = this.clienteEncontrado.direccion;
                    }
                    if (!this.clienteEncontrado && this.condicion_nit==1){
                        this.cliente = null;
                        this.facturaData.nombre_receptor = '';
                        this.facturaData.direccion = '';
                        this.condicion_nit = 0;
                    }
					
                }
				
                
            },
            deep: true,
        },
        cliente: function () {
            if(this.cliente){
                notificaciones.round_info_noti($.msg='Se encontró al Cliente: '+this.clienteEncontrado.nombre + ' ' + this.clienteEncontrado.apellido);
            }
        },
},
computed: {
    subtotalFormateado() {

        return this.$filters.currencyGTQ(this.facturaData.subtotal);
    },
    totalFormateado() {
      // Aplica el filtro currencyGTQ al valor de ventaData.total
        return this.$filters.currencyGTQ(this.facturaData.total);
    },
    cambioFormateado(){
        return this.$filters.currencyGTQ(this.cambio);
    },  
    productoDataFilter() {
        if (this.busqueda != ''){
            return this.productosBusqueda.filter((item) => {
                return  item.nombre.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigo.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.busqueda.toLowerCase()))
        })
        }else{
            return [];
        }
    },
},


methods: {
    async getClientesData() {
            const response = await fetch(this.URL_TENANT+'/api/cliente_nit/');
            this.clientesData = await response.json();
        },
    async productoEliminar(id, nombre){
        this.productoDetalleEliminar.id = id;
        this.productoDetalleEliminar.nombre = nombre;
    },
    async calcularConPrecio() {
    const total_facturar_ = this.productoEncontrado.precio_facturar * this.productoEncontrado.cantidad_totales;
    this.productoEncontrado.total_facturar = total_facturar_.toFixed(6);
    },

    async calcularConTotal() {
        const precio_facturar_ = this.productoEncontrado.total_facturar / this.productoEncontrado.cantidad_totales;
        this.productoEncontrado.precio_facturar = precio_facturar_.toFixed(6);
    },

    async calcularConCantidad() {
        const cantidad_totales = this.productoEncontrado.cantidad_unidad_medida_select * this.productoEncontrado.cantidad_facturar;
        const precio_facturar_ = this.productoEncontrado.precio / this.productoEncontrado.cantidad_unidad_medida_select;
        const total_facturar_ = precio_facturar_ * cantidad_totales;

        this.productoEncontrado.cantidad_totales = cantidad_totales;
        this.productoEncontrado.precio_facturar = precio_facturar_.toFixed(6);
        this.productoEncontrado.total_facturar = total_facturar_.toFixed(6);
    },
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresa = await response.json();
    },

    //Antes de Finalizar:
    async calcularTotal(){
        this.ventaData.total = this.ventaData.subtotal - this.ventaData.descuento;
    },

    // Finalizar Venta:
    async validarProductoInventario(){
        console.log("Estoy en validar producto inventario");
        this.finaliza_status = true;
        return this.finaliza_status; // Se agregó para solventar problema de Dibeorsa
    
    },
    async actualizarFactura(datosFactura) {
        try {
            const response = await fetch(this.URL_TENANT + '/api/' + this.id_factura.id_factura + '/actualizar/', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json', // Cabecera para JSON
                    'X-CSRFToken': Cookies.get('csrftoken'), // CSRF token
                },
                body: JSON.stringify(datosFactura) // Datos en formato JSON
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error al actualizar la factura:', errorData);
                return { success: false, error: errorData };
            }

            const data = await response.json();
            console.log('Factura actualizada exitosamente:', data);
            notificaciones.round_success_noti($.msg = 'Datos del cliente actualizados');

            return { success: true, data };
        } catch (error) {
            console.error('Error en la solicitud:', error);
            notificaciones.round_error_noti($.msg = 'No se guardaron los cambios en los datos del cliente.');
            return { success: false, error };
        }
    },
    async finalizarFactura(){
        this.facturando = true;
        if(this.facturaData.gran_total >= 2500 && this.facturaData.id_receptor === 'CF'){
            notificaciones.round_error_noti($.msg='No se puede certificar montos mayores a 2,500 como CF.');
            return;
        }
        if(this.facturaData.id_receptor != this.nit_factura)
        {
            const datosFacturaCambio = {
                id_receptor: this.facturaData.id_receptor,
                nombre_receptor: this.facturaData.nombre_receptor,
                direccion: this.facturaData.direccion
            }
            this.actualizarFactura(datosFacturaCambio);
        }
        try {
            const certificarResponse = await fetch(this.URL_TENANT + '/api/certificar_factura/' + this.id_factura.id_factura + '/CONTADO/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }
            });
            if (!certificarResponse.ok) {
                // Manejamos el error si el estado no es 200
                console.error(`Error al certificar factura: ${certificarResponse.status} ${certificarResponse.statusText}`);
                notificaciones.round_error_noti($.msg='Error, No se pudo certificar la factura.');
                
            }else{
                notificaciones.round_success_noti($.msg='Factura Certificada Exitosamente');
                document.getElementById('close').click();
                this.factura_creada = true;
                this.facturando = false;
                this.$emit('factura-creada');
                // Abrir el PDF en una nueva ventana para imprimir automáticamente
                if(this.empresa[0].impresion_ticket == true){
                    window.open(`/venta/generar_ticket_88mm_factura/${this.facturaData.id}/`, '_blank');
                }else{
                    window.open('/venta/generar_impresion_factura/'+this.facturaData.id+'/', '_blank');
                }
                // Redirigir después de 1.5 segundos
                setTimeout(function(){
                    location.href = '/venta/facturas/';
                }, 1500);
            }
        } catch (certificarError) {
            // Capturamos errores en el fetch
            console.error("Error al intentar certificar la factura:", certificarError);
        }
        this.estaFinalizandoFactura = false;

        

        

    },
    // Anular Venta:
    async anularFactura(){
        this.anulando = true;
        const anularResponse = await fetch(this.URL_TENANT + '/api/anular_factura/' + this.id_factura.id_factura + '/'+ this.anular_factura.motivo + '/', {
            method: 'POST',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            }
        });
        if (!anularResponse.ok) {
            // Manejamos el error si el estado no es 200
            console.error(`Error al anular factura: ${anularResponse.status} ${anularResponse.statusText}`);
            notificaciones.round_error_noti($.msg='Error, No se pudo anular la factura.');
            
        }else{
            notificaciones.round_success_noti($.msg='Factura Anulada Exitosamente');
            document.getElementById('close').click();
            setInterval(function(){location.href = '/venta/facturas/'},1500);
        }
        this.anulando = false;

    },
    // Modificar Cantidad de Productos:

    async eliminarDetalleFactura(){
        const formData = new FormData();
        formData.append("id", this.productoDetalleEliminar.id);
        await fetch(this.URL_TENANT+'/api/factura_detalle/'+this.productoDetalleEliminar.id+'/',{
        method: 'DELETE',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
        body: formData});
        notificaciones.round_success_noti($.msg='Producto Eliminado Exitosamente');
        this.getDetalleFacturaData();
        this.getFacturaData();
    },
    // Validadores:
    validateInputs(){
        this.nameError =  (!this.productoEncontrado.unidad_medida || this.productoEncontrado.unidad_medida.length === 0) ? ['Debe Elegir Unidad de Medida'] : [];
        this.nameError = (!this.productoEncontrado.cantidad_facturar) ? [...this.nameError, 'Debe Ingresar la Cantidad'] : this.nameError;
    },
    // Agregar Productos al Detalle:
    async agregarProductoDetalle(){
        this.validateInputs();
        if(this.nameError.length != 0){
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
                const formDataDet = new FormData();
                


                formDataDet.append("factura", this.id_factura.id_factura);
                formDataDet.append("numero_linea", this.detalleFacturaData.length+1);
                formDataDet.append("bien_servicio", "B");
                formDataDet.append("cantidad", this.productoEncontrado.cantidad_totales);
                formDataDet.append("unidad_medida", "UND");
                formDataDet.append("descripcion_producto", this.productoEncontrado.nombre);
                formDataDet.append("precio_unitario", this.productoEncontrado.precio_facturar);
                formDataDet.append("precio", this.productoEncontrado.total_facturar);
                formDataDet.append("total", this.productoEncontrado.total_facturar);
                formDataDet.append("im", "IM");
                formDataDet.append("cantidad_impuestos", 1);
                formDataDet.append("nombre_corto_impuesto", "IVA"); //Modificar luego al cambiar entidades
                formDataDet.append("unidad_gravable", 1);
                formDataDet.append("monto_gravable", 0);
                formDataDet.append("monto_impuesto", 0);
                await fetch(this.URL_TENANT+'/api/factura_detalle/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formDataDet})
                notificaciones.round_success_noti($.msg='Producto Agregado Exitosamente');
                document.getElementById('agregar_detalle_close2').click();
                this.getDetalleFacturaData();
                this.getFacturaData();
        }
    },
    //Quitar Objeto:
    async removeObjectArray(array, atributo) {
        //console.log("REMOVE ARRAY");
        if (array[atributo].length > 1) {
            array[atributo].shift();
        }
        if(atributo == 'unidad_medida'){
            this.seleccionUnidadMedida();
        }
    },
    async seleccionUnidadMedida(){
        this.obj = this.productoEncontrado.unidades_medida.filter((item) => {
            return  item.id == this.productoEncontrado.unidad_medida[0];
        })
        console.log("OBJ::::");
        console.log(this.obj);
        this.productoEncontrado.unidad_medida[0] = this.obj[0]['id'];
        this.productoEncontrado.precio = this.obj[0]['precio_venta'];
        this.productoEncontrado.inventario = this.obj[0]['producto_inventario']  / this.obj[0]['cantidad_unidad_medida'];
        this.productoEncontrado.cantidad_unidad_medida_select = this.obj[0]['cantidad_unidad_medida'];
        this.productoEncontrado.cantidad_facturar = 1;

        this.calcularConCantidad();
    },
    async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
    async getFacturaData() {
        const response = await fetch(this.URL_TENANT+'/api/factura/'+this.id_factura.id_factura+'/');
        this.facturaData = await response.json();
        this.nit_factura = this.facturaData.id_receptor;
    },
    async getDetalleFacturaData() {
        const response = await fetch(this.URL_TENANT+'/api/factura_detalle/?id_factura='+this.id_factura.id_factura);
        this.detalleFacturaData = await response.json();
    },
    async getProductosBusquedaData() {
        const response = await fetch(this.URL_TENANT+'/api/producto_busqueda/?tipo=venta');
        this.productosBusqueda = await response.json();
    },
    //Buscar Unidades de Medida por  producto:
    async buscarUnidadesProductoData(id_producto, barra = ''){
        const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida_busqueda/?id_producto='+id_producto);
        this.productoEncontrado.unidades_medida = await response.json();
        console.log("UNIDADES MEDIDASSSSSSS....");
        console.log(barra);
        console.log(this.productoEncontrado.unidades_medida);
        const unidadMedidaMenor = this.productoEncontrado.unidades_medida.reduce((min, unidad) =>
            unidad.cantidad_unidad_medida < min.cantidad_unidad_medida ? unidad : min
        );
        if(unidadMedidaMenor){
            this.productoEncontrado.unidad_medida[0] = unidadMedidaMenor.id;
            this.productoEncontrado.precio = unidadMedidaMenor.precio_venta;
            this.productoEncontrado.inventario = unidadMedidaMenor.producto_inventario / unidadMedidaMenor.cantidad_unidad_medida;
            this.productoEncontrado.cantidad_unidad_medida_select = unidadMedidaMenor.cantidad_unidad_medida;
            this.productoEncontrado.cantidad_facturar = 1;
            this.calcularConCantidad();


        }
        if(barra != ''){
            this.obj = this.productoEncontrado.unidades_medida.filter((item) => {
                return  item.codigo_barra == barra;
            })
            console.log("OBJ::::");
            console.log(this.obj);
            this.productoEncontrado.unidad_medida[0] = this.obj[0]['id'];
            this.productoEncontrado.precio = this.obj[0]['precio_venta'];
            this.productoEncontrado.inventario = this.obj[0]['producto_inventario'] / this.obj[0]['cantidad_unidad_medida'] ;
            this.productoEncontrado.cantidad_unidad_medida_select = this.obj[0]['cantidad_unidad_medida'];
            this.productoEncontrado.cantidad_facturar = 1;
            this.calcularConCantidad();



        }
    },
    async limpiarProductoEncontrado(){
        this.productoEncontrado = {
            codigo: '',
            cantidad: '',
            precio: '',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
        }
    },
    // Buscar Producto por Barra:
    async buscarProductoPorBarraData(){
        const response = await fetch(this.URL_TENANT+'/api/producto_busqueda/?tipo=venta');
        this.productosBusqueda = await response.json();
    },
    async buscarProductoPorBarra(dato, tipo){
        
        this.limpiarProductoEncontrado();
        if(tipo === 'barra'){
            console.log("barra.....");
            console.log(dato);
            try{
                this.obj = this.productosBusqueda.filter((item) => {
                    return  item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(dato.toLowerCase())) || item.codigo.toLowerCase().includes(dato.toLowerCase())
                })
            }catch(error){
                console.log("firulais");
                this.obj = [];
            }
            //let obj = this.productosBusqueda.find(item => item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.dato.toLowerCase())));
            console.log(this.obj);
            //console.log(this.obj[0].id);
            if(this.obj.length >0){
                console.log(this.obj[0]);
                this.productoEncontrado.producto = this.obj[0].id;
                this.productoEncontrado.codigo = this.obj[0].codigo;
                this.productoEncontrado.nombre = this.obj[0].nombre;
                this.id_barra = '';
                this.obj.forEach((element) =>{
                    console.log(element);
                    for (let xx of element.codigos_barra_producto){
                        console.log("es:" + xx.id);
                        if (xx.codigo_barra == dato){
                            this.id_barra = xx.id;
                        }
                    }
                })
                this.buscarUnidadesProductoData(this.obj[0].id, this.id_barra );
                // Código para mostrar un el Modal desde Vue:
                this.myModal = new Modal(document.getElementById('modalAgregarProducto'), {
                        keyboard: false
                    });
                this.myModal.show();
            }else{
                console.log("No se encontró");
                notificaciones.round_error_noti($.msg='No se encontró el Código del Producto | Código de Barra');
            }
        }
        else if(tipo === 'id'){
            console.log("id.....");
            console.log(dato);
            this.obj = this.productosBusqueda.filter((item) => {
                    return  item.id.includes(dato);
                });
            this.productoEncontrado.producto = this.obj[0].id;
            this.productoEncontrado.nombre = this.obj[0].nombre;
            this.productoEncontrado.codigo = this.obj[0].codigo;
            this.buscarUnidadesProductoData(this.obj[0].id);
            document.getElementById('agregar_detalle_close').click();
            // Código para mostrar un el Modal desde Vue:
            this.myModal = new Modal(document.getElementById('modalAgregarProducto'), {
                        keyboard: false
                    });
                this.myModal.show();
        }
    },
    accionTecla(event) {
        if (event.key === 'F1') {
        event.preventDefault();
            this.$refs.buscarProductoBtn.click();
        }
        if (event.key === 'F12') {
        event.preventDefault();
            this.$refs.finalizarFacturaBtn.click();
        }
      // puedes agregar más acciones para otras teclas aquí
    },
    async getUserData(){
		const response = await fetch(this.URL_TENANT+'/api/user/');
		this.userData = await response.json();
	},
},

beforeMount(){
    this.id_factura = JSON.parse(document.getElementsByTagName('body')[0].getAttribute('data') || '{}');
},
created() {
    // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    console.log("ID::::");
    console.log(params);
},
mounted(){
    window.addEventListener('keydown', this.accionTecla);
    this.getFacturaData();
    this.getDetalleFacturaData();
    this.getProductosBusquedaData();
    this.getEmpresaData();
	this.getClientesData();

    console.log("REFS:");
    for (var ref in this.$refs) {
        console.log(this.$refs[ref]);
    }
    this.$watch(
        "$refs.child.searchingInputValue",
        () => (this.busqueda = this.$refs.child.searchingInputValue),
    );
    var myModalEl = document.getElementById('agregarDetalleModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.busquedaPorTexto.focus();
            });
        });

},
beforeUnmount() {
    window.removeEventListener('keydown', this.accionTecla);
    var myModalEl = document.getElementById('agregarDetalleModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.busquedaPorTexto.focus();
        });
    });
    var myModalEl2 = document.getElementById('finalizarDetalleFactura')
    myModalEl2.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.descuento.focus();
            this.$refs.descuento.select();
        });
    });
},
}
</script>
