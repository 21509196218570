<template>
<div class="page-wrapper">
			<div class="page-content">
			<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Catálogo</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Productos</li>

							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
				<div  class="row">
					<div class="col-12">
						<div class="card">
							<div class="card-body">
							<div class="row align-items-center">
								<!-- Columna para botones -->
								<div class="col-lg-6 col-xl-6 d-flex justify-content-start">
									<a href="/catalogo/producto/agregar" class="btn btn-light mb-3 mb-lg-0 me-2"><i class='bx bxs-plus-square'></i> Nuevo Producto</a>
									<a href="/inventario/reporte_inventario_pdf/" target="_blank" class="btn btn-light mb-3 mb-lg-0 me-2"><i class='bx bxs-file-pdf'></i> Reporte de Inventario(Pdf)</a>
									<a href="/inventario/reporte_inventario_excel/" target="_blank" class="btn btn-light mb-3 mb-lg-0 me-2"><i class='bx bxs-spreadsheet'></i> Reporte de Inventario(Excel)</a>
								</div>

								<!-- Columna para buscador -->
								<div class="col-lg-6 col-xl-6">
									<form class="float-lg-end">
										<div class="row row-cols-lg-auto g-2">
											<div class="col-12">
												<div class="position-relative">
													<input type="text" class="form-control ps-5" autofocus v-model="buscarProducto" placeholder="Buscar Producto..."> 
													<span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
												</div>
											</div>
											<!-- Aquí puedes colocar otros elementos si es necesario -->
										</div>
									</form>
								</div>
							</div>
						</div>

						</div>
					</div>
				</div>
				<!--INICIO MODIFICAR-->
				<div v-if="option==3" class="card">
				<!--INICIO MODIFICAR-->
				<div class="card">
				<div class="card-body">
					<div class="row">
					<ul class="nav nav-tabs" role="tablist">
						<li class="nav-item" role="presentation">
							<a class="nav-link active" data-bs-toggle="tab" href="#datosProducto" role="tab" aria-selected="true">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-spreadsheet font-18 me-1'></i>
									</div>
									<div class="tab-title">Datos del Producto</div>
								</div>
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a class="nav-link" data-bs-toggle="tab" href="#codigosBarraProducto" role="tab" aria-selected="false">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-barcode font-18 me-1'></i>
									</div>
									<div class="tab-title">Códigos de Barras</div>
								</div>
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a class="nav-link" data-bs-toggle="tab" href="#unidadesMedidaProducto" role="tab" aria-selected="false">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-package font-18 me-1'></i>
									</div>
									<div class="tab-title">Unidades de Medida</div>
								</div>
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a class="nav-link" data-bs-toggle="tab" href="#proveedoresProducto" role="tab" aria-selected="false">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-group font-18 me-1'></i>
									</div>
									<div class="tab-title">Proveedores</div>
								</div>
							</a>
						</li>
						<div class="ms-auto">
							<button type="button" @click="cambiarEstado(idOption=1, idProducto=0)" class="btn btn-light" style="margin-right:10px;">Regresar</button>
							<button type="button"  @click="modificarProducto()" class="btn btn-light">Modificar Producto</button>
					</div>
					</ul>
					</div>
					<div class="tab-content py-3">
						<div class="tab-pane fade show active" id="datosProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5>Modificar Producto</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-8">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="mb-3">
												<h6><label class="form-label">Código</label>
												<input v-model="productoModificar[0].codigo" type="text" class="form-control" id="codigoNuevo" placeholder="Ingrese el Código del Producto">
											</h6></div>
											<div class="mb-3">
												<h6><label class="form-label">Nombre Producto *</label>
												<input v-model="productoModificar[0].nombre" type="text" class="form-control" id="nombreNuevo" placeholder="Ingrese el Nombre del Producto">
											</h6></div>
											<div class="mb-3">
												<h6><label class="form-label">Marca</label>
												<input v-model="productoModificar[0].marca" type="text" class="form-control" id="marcaNuevo" placeholder="Ingrese la Marca">
											</h6></div>
											<div class="mb-3">
												<h6><label class="form-label">Descripción</label>
												<textarea v-model="productoModificar[0].descripcion" class="form-control" placeholder="Ingresa una Descripción del Producto" id="descripcionNuevo" rows="3"></textarea>
											</h6></div>
											</div>
											<div class="mb-3">
												<h6><label class="form-label">Observaciones</label>
												<textarea v-model="productoModificar[0].observaciones" class="form-control" placeholder="Observaciones del Producto" id="observacionesNuevo" rows="3"></textarea>
											</h6></div>
											</div>
										</div>
										<div class="col-lg-4">
											<div class="border border-3 p-4 rounded">
											<div class="row g-3">
												<div class="input-group mb-3">
													<div class="input-group-text">
														<input v-model="productoModificar[0].permitir_compra" class="form-check-input"  id="permitirCompraNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
													</div>
													<input type="text" class="form-control" disabled v-if="productoModificar[0].permitir_compra === true" value="Permitir Compra" aria-label="Text input with checkbox">
													<input type="text" class="form-control" disabled v-if="productoModificar[0].permitir_compra === false" value="No Permitir Compra" aria-label="Text input with checkbox">
												</div>
												<div class="input-group mb-3">
													<div class="input-group-text">
														<input v-model="productoModificar[0].permitir_venta" class="form-check-input"  id="permitirVentaNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
													</div>
													<input type="text" v-if="productoModificar[0].permitir_venta === true" class="form-control" disabled value="Permitir Venta" aria-label="Text input with checkbox">
													<input type="text" v-if="productoModificar[0].permitir_venta === false" class="form-control" disabled value="No Permitir Venta" aria-label="Text input with checkbox">
												</div>
												<div class="input-group mb-3">
													<div class="input-group-text">
														<input @click="cambiarEstadoProductoModificar(productoModificar[0].estado)" class="form-check-input" :checked="productoModificar[0].estado === 'A'"   type="checkbox" aria-label="Checkbox for following text input">
													</div>
													<input type="text" class="form-control" disabled v-if="productoModificar[0].estado === 'A'" value="Producto Activo" aria-label="Text input with checkbox">
													<input type="text" class="form-control" disabled v-if="productoModificar[0].estado === 'I'" value="Producto Inactivo" aria-label="Text input with checkbox">
												</div>
												<div class="col-12">
													<h6><label for="inputProductType" class="form-label">Categoría</label></h6>
													<vue-select  :options="categoriasData"
														v-model="selectedCategoria"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Selecciona una Categoría"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Selecciona una Categoría"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione una Categoría</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
												</div>
												<h6><label class="form-label">Subir Imagen(es)</label><br></h6>
												<div class="input-group mb-3">
													<input type="file" class="form-control" id="imagenesNuevo" multiple>
													<label class="input-group-text">Subir</label>
												</div>
												<button type="button" data-bs-toggle="modal" :data-bs-target="'#ImagenesModalMod'+productoModificar[0].id" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Ver Imágenes <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{productoModificar[0].imagenes_producto.length}}</span>
													</button>
												<!-- Modal -->
												<div class="modal fade" :id="'ImagenesModalMod'+productoModificar[0].id" tabindex="-1" aria-hidden="true">
													<div class="modal-dialog modal-dialog-centered">
														<div class="modal-content">
															<div class="modal-header">
																<h5 class="modal-title">Imágenes del Producto</h5>
																<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
															</div>
															<div class="modal-body">
																<div class="card">
																	<div class="card-body">
																		<div :id="'carouselExampleCaptions'+productoModificar[0].id" class="carousel slide" data-bs-ride="carousel">
																			<ol class="carousel-indicators">
																				<li  v-for="(im, index) in productoModificar[0].imagenes_producto" :key="im.id" :data-bs-target="'#carouselExampleCaptions'+productoModificar[0].id" :data-bs-slide-to="index" :class="{active: index === 0}"></li>
																			</ol>
																			<div class="carousel-inner">
																				<div class="carousel-item" :class="{active: index === 0}" v-for="(im, index) in productoModificar[0].imagenes_producto" :key="im.id">
																					<img :src="im.imagen" class="d-block w-100" alt="...">
																					<div class="carousel-caption d-none d-md-block">
																					</div>
																				</div>
																			</div>
																			<a class="carousel-control-prev" :href="'#carouselExampleCaptions'+productoModificar[0].id" role="button" data-bs-slide="prev">	<span class="carousel-control-prev-icon" aria-hidden="true"></span>
																				<span class="visually-hidden">Previous</span>
																			</a>
																			<a class="carousel-control-next" :href="'#carouselExampleCaptions'+productoModificar[0].id" role="button" data-bs-slide="next">	<span class="carousel-control-next-icon" aria-hidden="true"></span>
																				<span class="visually-hidden">Next</span>
																			</a>
																		</div>
																	</div>
																</div>
															</div>
															<div class="modal-footer">
																<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
							</div>
						</div>
						<!-- AQUI VA CODIGOS DE BARRA -->
						<div class="tab-pane fade" id="codigosBarraProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5>Códigos de Barra</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="col-5">
												<h6><label class="form-label">Código de Barra</label></h6>
												<input v-model="codigoBarra" type="text" class="form-control" id="codigoBarra" placeholder="Ingrese Nuevo Código de Barra">
											</div>
											<div class="col-5">
												<h6><label class="form-label">Prioridad</label></h6>
												<vue-select  :options="estadosPrioridad"
														v-model="selectedPrioridad"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Seleccione una Prioridad"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Seleccione una Prioridad"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione una Prioridad</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
											</div>
											<div class="col-2">
												<h6><label class="form-label">Acción</label></h6>
												<input  type="button" class="form-control btn-light" :disabled="agregando" @click="agregarCodigosBarra()" value="Agregar Codigo Barra" >
											</div>
											</div>
											</div>
										</div>
									</div><!--end row-->
									</div>
									<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Código de Barra</h6></th>
										<th><h6>Prioridad</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in productoModificar[0].codigos_barra_producto" :key="item.id">
										<td ><h6>
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">{{item.codigo_barra}}</h6>
												</div>
											</div></h6>
										</td>
										<td><h6>
											<label v-if="item.estado === 'P'">
											Principal
											</label>
											<label v-if="item.estado === 'S'">
											Secundaria
											</label></h6>
										</td>
										<td><h6>
											<div class="d-flex order-actions">
												<a data-bs-toggle="modal" data-bs-target="#editarCodigoDeBarra" @click="fillcodigoBarraMod(item, productoModificar[0].nombre)"><i  class='bx bxs-edit'></i></a>
											</div></h6>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
								</div>
							</div>
						</div>
						<!-- AQUI TERMINA CODIGOS DE BARRA -->
						<!-- AQUI VA UNIDADES MEDIDA -->
						<div class="tab-pane fade" id="unidadesMedidaProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5>Unidades de Medida...</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="col-2">
												<h6><label class="form-label">Nombre</label></h6>
												<input v-model="nombreUnidadMedida" type="text" class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Cantidad Unidades</label></h6>
												<input v-model="cantidadUnidadMedida" type="text" class="form-control" id="cantidadUnidadMedida" placeholder="IngreseCantidad de Unidades">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Precio Compra</label></h6>
												<input v-model="precioCompraUnidadMedida" type="number" step="0.01" class="form-control" id="precioCompraUnidadMedida" placeholder="Ingrese Precio de Compra">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Precio Venta</label></h6>
												<input v-model="precioVentaUnidadMedida" type="number" step="0.01" class="form-control" id="precioVentaUnidadMedida" placeholder="Ingrese el Precio Venta">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Codigo de Barra</label></h6>
												<!--<input v-model="codigobarraUnidadMedida" type="text" class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">-->
												<vue-select  :options="productoModificar[0].codigos_barra_producto"
													v-model="codigobarraUnidadMedida"
													label-by="codigo_barra"
													track-by="codigo_barra"
													value-by="codigo_barra"
													search-placeholder="Selecciona una Categoría"
													multiple
													collapse-tags="true"
													taggable
													searchable
													placeholder="Selecciona una Categoría"
													close-on-select>
													<template #label="{ multiple }">
													<template v-if="multiple">
													<div style="color:white;">{{ multiple.codigo_barra }}</div>
													</template>
													<template v-else > <div style="color:white;">Seleccione un Código de Barra</div></template>
													</template>
													<template #dropdown-item="{ option }">
													<div style="color:white;">{{ option.codigo_barra }}</div>
													</template>
												</vue-select>
											</div>
											<div class="col-2">
												<h6><label class="form-label">Acción</label></h6>
												<input  type="button" class="form-control btn-light" :disabled="agregando" @click="agregarUnidadesMedida()" value="Agregar Unidad Medida" >
											</div>
											</div>
										</div>
										<div class="table-responsive">
											<table class="table mb-0">
												<thead class="table-light">
													<tr>
														<th><h6>Nombre</h6></th>
														<th><h6>Cantidad Unidades</h6></th>
														<th><h6>Precio Compra</h6></th>
														<th><h6>Precio Venta</h6></th>
														<th><h6>Código de Barra</h6></th>
														<th><h6>Estado</h6></th>
														<th><h6>Acciones</h6></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="item in productoModificar[0].unidades_medida_producto" :key="item.id">
														<td ><h6>
															<div class="d-flex align-items-center">
																<div>
																	<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
																</div>
																<div class="ms-2">
																	<h6 class="mb-0 font-14">{{item.nombre_unidad_medida}}</h6>
																</div>
															</div></h6>
														</td>
														<td><h6>{{item.cantidad_unidad_medida}}</h6></td>
														<td><h6>{{item.precio_compra}}</h6></td>
														<td><h6>{{item.precio_venta}}</h6></td>
														<td><h6>{{item.codigo_barra_codigo}}</h6></td>
														<td v-if="item.estado == 'A'"><h6>Activo</h6></td>
														<td v-if="item.estado == 'I'"><h6>Inactivo</h6></td>
														<td><h6>
															<div class="d-flex order-actions">
																<a data-bs-toggle="modal" data-bs-target="#editarUnidadMedida" @click="fillunidadMedidaMod(item)"><i  class='bx bxs-edit'></i></a>
															</div></h6>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
							</div>
						</div>
						<!-- AQUI TERMINA UNIDADES DE MEDIDA -->
						<!-- AQUI VA PROVEEDORES -->
						<div class="tab-pane fade" id="proveedoresProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5>Proveedores</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="col-5">
													<h6><label class="form-label">Proveedor</label></h6>
													<vue-select  :options="proveedoresData"
														v-model="selectedProveedor"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Selecciona un Proveedor"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Selecciona un Proveedor"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
												</div>
												<div class="col-5">
												<h6><label class="form-label">Estado</label></h6>
												<vue-select  :options="estadosProveedor"
														v-model="selectedEstadoProveedor"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Seleccione un Estado"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Seleccione un Estado"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione un Estado</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
											</div>
											<div class="col-2">
												<h6><label class="form-label">Acción</label></h6>
												<input  type="button" class="form-control btn-light" @click="agregarProveedores()" value="Agregar Proveedor" >
											</div>
											</div>
										</div>
										<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Proveedor</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in productoModificar[0].proveedores_producto" :key="item.id">
										<td ><h6>
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">{{item.nombre_proveedor}}</h6>
												</div>
											</div></h6>
										</td>
										<td><h6>
											<label v-if="item.estado === 'A'">
											Activo
											</label>
											<label v-if="item.estado === 'I'">
											Inactivo
											</label></h6>
										</td>
										<td>
											<div class="d-flex order-actions">
												<a href="javascript:;" class="ms-3" data-bs-toggle="modal" data-bs-target="#eliminarModal"><i class='bx bxs-trash'></i></a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
							</div>
						</div>
						<!-- AQUI TERMINA PROVEEDORES -->
					</div>
				</div>
			</div>
			<!--FIN MODIFICAR-->

			</div>
			<!--FIN MODIFICAR-->
				<div v-if="option==1" class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
						</div>
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Código</h6></th>
										<th><h6>Nombre</h6></th>
										<th><h6>Categoría</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Imágenes</h6></th>
										<th><h6>Códigos de Barra</h6></th>
										<th><h6>Total de Unidades</h6></th>
										<th><h6>Unidades de Medida</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="itemProd in productoDataFilter" :key="itemProd.id">
											<td>
											<h6><label >{{itemProd.codigo}}</label></h6>
											</td>
											<td>
											<h6><label >{{itemProd.nombre}}</label></h6>
											</td>
											<td>
											<h6><label >{{itemProd.categoria_nombre}}</label></h6>
											</td>
											<td>
											<h6><label v-if="itemProd.estado === 'A'">Activo</label>
											<label v-if="itemProd.estado === 'I'">Inactivo</label></h6>
											</td>
											<td>
											<h6><div v-if="itemProd.imagenes_producto.length != 0" class="col">
												<button type="button" data-bs-toggle="modal" :data-bs-target="'#ImagenesModal'+itemProd.id" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Ver Imágenes <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{itemProd.imagenes_producto.length}}</span>
												</button>
												<!-- Modal -->
												<div class="modal fade" :id="'ImagenesModal'+itemProd.id" tabindex="-1" aria-hidden="true">
													<div class="modal-dialog modal-dialog-centered">
														<div class="modal-content">
															<div class="modal-header">
																<h5 class="modal-title">Imágenes del Producto</h5>
																<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
															</div>
															<div class="modal-body">
																<div class="card">
																	<div class="card-body">
																		<div :id="'carouselExampleCaptions'+itemProd.id" class="carousel slide" data-bs-ride="carousel">
																			<ol class="carousel-indicators">
																				<li  v-for="(im, index) in itemProd.imagenes_producto" :key="im.id" :data-bs-target="'#carouselExampleCaptions'+itemProd.id" :data-bs-slide-to="index" :class="{active: index === 0}"></li>
																			</ol>
																			<div class="carousel-inner">
																				<div class="carousel-item" :class="{active: index === 0}" v-for="(im, index) in itemProd.imagenes_producto" :key="im.id">
																					<img :src="im.imagen" class="d-block w-100" alt="...">
																					<div class="carousel-caption d-none d-md-block">
																					</div>
																				</div>
																			</div>
																			<a class="carousel-control-prev" :href="'#carouselExampleCaptions'+itemProd.id" role="button" data-bs-slide="prev">	<span class="carousel-control-prev-icon" aria-hidden="true"></span>
																				<span class="visually-hidden">Previous</span>
																			</a>
																			<a class="carousel-control-next" :href="'#carouselExampleCaptions'+itemProd.id" role="button" data-bs-slide="next">	<span class="carousel-control-next-icon" aria-hidden="true"></span>
																				<span class="visually-hidden">Next</span>
																			</a>
																		</div>
																	</div>
																</div>
															</div>
															<div class="modal-footer">
																<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
															</div>
														</div>
													</div>
												</div>
											</div></h6>
											</td>
											<td>
											<h6><div v-for='cb in itemProd.codigos_barra_producto' :key="cb.id" class="col">
											<ul>
												<li>
												{{cb.codigo_barra}}
												</li>
											</ul>
											</div></h6>
											</td>
											<td>
												<h6><label >{{itemProd.inventario}}</label></h6>
											</td>
											<td>
											<h6><div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
											<ul>
												<li>
												{{um.nombre_unidad_medida}} (Inventario: {{(um.inventario).toFixed(2)}})
												</li>
											</ul>
											</div></h6>
											</td>
										<td>
											<button type="button" @click="cambiarEstado(idOption = 3, idProducto = itemProd.id)" class="btn btn-light position-relative me-lg-5 bx bx-edit">
											</button>
											<button type="button" class="btn btn-light position-relative me-lg-5 bx bx-trash">
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!-- Paginacion -->
					<div class="card-body">
						<nav aria-label="Page navigation example">
								<ul class="pagination">
									<li class="page-item">
										<a class="page-link" href="javascript:;" @click="cambiarUrl(productosData.links.previous, 'atras')">Atrás</a>
									</li>
									<li class="page-item" :class="{active: indexPagination == index}" v-for="index in productosData.total_pages" :key="index">
										<a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/producto/?page='+index+'', index)">{{ index }}</a>
									</li>
									<li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(productosData.links.next, 'adelante')">Siguiente</a>
									</li>
								</ul>
						</nav>
					</div>
                    <!-- Fin Paginacion -->
				</div>

				<div v-if="option==2" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 product-grid">
					<div class="col" v-for="item in productoDataFilter" :key="item.id">
						<div class="card" >
							<img v-if="item._imagenes.length" :src="item._imagenes[0].imagen" class="card-img-top" alt="...">
							<div class="">
								<div class="position-absolute top-0 end-0 m-3 product-discount"><span class="">-10%</span></div>
							</div>
							<div class="card-body">
								<h5 class="card-title cursor-pointer">{{item.nombre}}</h5>
								<div class="clearfix">
									<p class="mb-0 float-start"><strong>Codigo de Barra: </strong> {{item.codigo_barra}}</p>
								</div>
								<div class="clearfix">
									<p class="mb-0 float-start"><strong>Marca: </strong> {{item.marca}}</p>
								</div>
								<div class="clearfix">
									<p class="mb-0 float-start"><strong>Categoría: </strong> {{item.categoria_nombre}}</p>
								</div>
								<div class="d-flex align-items-center mt-3 fs-6">
									<div class="cursor-pointer">
										<p class="mb-0 ms-auto"><strong>Precio Venta: </strong> Q.{{item.precio_venta}}</p>
									</div>
								<p class="mb-0 ms-auto"><span class="me-2 ">Inv: </span>{{item.inventario}}</p>
								</div>
							</div>
						</div>
					</div>
				</div><!--end row-->


			</div>
		</div>

<!-- Modal Ver Detalle -->
<div class="modal fade" id="editarUnidadMedida" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Editar Unidad de Medida</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <div class="row g-3">
							<div class="col-12">
								<h6>
								<label class="form-label">Producto</label>
								<input v-model="unidadMedidaMod.nombre_producto" type="text" readonly class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
								</h6>
							</div>
							<div class="col-12">
								<h6>
								<label class="form-label">Unidad de Medida</label>
								<button
									class="btn btn-sm"
									:class="{'btn-light': !modUnidadProd, 'btn-warning': modUnidadProd}"
									@click="toggleUnidadProd">
									<i class='order-actions bx bxs-edit'></i>
								</button>
								<input v-model="unidadMedidaMod.nombre_unidad_medida" type="text" readonly class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
								</h6>
							</div>
							<div v-if="modUnidadProd" class="col-12">
								<h6>
								<label class="form-label">Nuevo Nombre de Unidade de Medida</label>
								<input v-model="nombreUnidadMedida" type="text" class="form-control" id="nombreUnidadMedida" placeholder="Ingrese el Nuevo Nombre de la Unidad de Medida">
								</h6>
							</div>
							<div v-if="modUnidadProd" class="col-12">
								<h6>
								<label class="form-label">Cantidad de Unidades</label>
								<input v-model="cantidadUnidadMedida" type="text" class="form-control" id="cantidadUnidadMedida" placeholder="Ingrese la Nueva Cantidad de la Unidad de Medida">
								</h6>
							</div>
							<div class="col-12">
								<h6>
								<label class="form-label">Precio Compra</label>
								<input v-model="unidadMedidaMod.precio_compra" type="number" class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
								</h6>
							</div>
							<div class="col-12">
								<h6>
								<label class="form-label">Precio Venta</label>
								<input v-model="unidadMedidaMod.precio_venta" type="number" class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
								</h6>
							</div>
							<div class="col-sm-12" v-if="unidadMedidaMod.estado">
								<h6>
								<label class="form-label">Estado</label>
								<vue-select
									@blur="removeObjectArray(unidadMedidaMod.estado, 'id')"
									:options="estados"
									v-model="unidadMedidaMod.estado"
									label-by="nombre"
									track-by="nombre"
									value-by="id"
									search-placeholder="Selecciona un Estado"
									multiple
									collapse-tags="true"
									taggable
									searchable
									placeholder="Selecciona una Estado"
									close-on-select>
									<template #label="{ multiple }">
									<template v-if="multiple">
									<div style="color:white;">{{ multiple.nombre }}</div>
									</template>
									<template v-else > <div style="color:white;">Seleccione una Estado</div></template>
									</template>
									<template #dropdown-item="{ option }">
									<div style="color:white;">{{ option.nombre }}</div>
									</template>
								</vue-select>
								</h6>
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="modificarUnidadMedida()">Modificar</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
<!-- Modal Ver Detalle -->
<div class="modal fade" id="editarCodigoDeBarra" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Editar Código de Barra</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <div class="row g-3">
							<div class="col-12">
								<label class="form-label">Producto</label>
								<input v-model="codigoBarraMod.nombre_producto" type="text" readonly class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
							</div>
							<div class="col-12">
								<label class="form-label">Código De Barra</label>
								<input v-model="codigoBarraMod.codigo_barra" type="text"  class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="modificarCodigoBarra()">Modificar</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
import VueNextSelect from 'vue-next-select'
console.log(Cookies.get());
export default {
    name: 'producto_component',
    components:{
        'vue-select': VueNextSelect,
    },
data() {
	return{
		URL_TENANT: '',
		url: "/api/producto/",
        indexPagination: 1,
		//Global Data:
		globalProducto: [],
        productosData: [],
        agregando: false,
		productosSinPagData: [],
		buscarProducto: '',
		option: 1,
		selectedProducto: null,
		listadoTabla: [],
		estados: [
            {
                id: 'A',
                nombre: 'Activo',
            },
            {
                id: 'I',
                nombre: 'Inactivo',
            }
        ],
		//para Modificar Producto:
		categoriasData: [],
		selectedCategoria: '',
		productoModificar: [],
		unidadMedidaMod: {
			estado: ''
		},
		codigoBarraMod:{},
		//modificarEstado:,
		codigoBarra: '',
		selectedPrioridad: '',
		estadosPrioridad: [
            {
                id: 'Principal',
                nombre: 'Principal',
            },
            {
                id: 'Secundario',
                nombre: 'Secundario',
            }
        ],
		modUnidadProd: false,
		nombreUnidadMedida: 'Unidad',
		cantidadUnidadMedida: 1,
		precioCompraUnidadMedida: '',
		precioVentaUnidadMedida: '',
		codigobarraUnidadMedida: '',
		proveedores: [],
		selectedProveedor: '',
		selectedEstadoProveedor: '',
		selectedEstado: '',
		estadosProveedor: [{
			id: 'A',
			nombre: 'Activo',
		},
		{
			id: 'I',
			nombre: 'Inactivo',
		}
		],
		//Modal:
		idProductoEliminar: '',
		nombreProductoEliminar: ''
		}
	},

computed: {
	estadoComputado: {
		get() {
        // Devuelve el estado actual de unidadMedidaMod.estado
        return this.unidadMedidaMod.estado;
		},
		set(valor) {
        // Actualiza el estado de unidadMedidaMod.estado basado en el valor booleano
        this.unidadMedidaMod.estado = valor ? 'A' : 'I';
		},
    },
	globalProductoFilter(){
		return this.globalProducto.filter((item) => {return item.valor === true})
	},
    productoDataFilter() {
        console.log("LENGT");
        console.log(this.productosData.length);
        if (this.buscarProducto != ''){
            return this.productosSinPagData.filter((item) => {
            return item.nombre.toLowerCase().includes(this.buscarProducto.toLowerCase()) || (item.codigo ? item.codigo.toLowerCase().includes(this.buscarProducto.toLowerCase()) : false)
        })
        }else{
			console.log("BUSQUEDA VACÍA");
            return this.productosData.results;
        }

    },
},

methods: {
	async removeObjectArray(array) {
        //console.log("REMOVE ARRAY");
        if (array.length > 1) {
            array.shift();
        }
    },
	actualizarEstado(event) {
      // Actualiza estadoComputado basado en si el checkbox está seleccionado o no
		this.estadoComputado = event.target.checked;
    },
	toggleUnidadProd() {
        this.modUnidadProd = !this.modUnidadProd;
    },
	async fillunidadMedidaMod(item){
		console.log("ITEM:");
		console.log(item);
		this.unidadMedidaMod = {
			id: item.id,
			nombre_producto: item.nombre_producto,
			nombre_unidad_medida: item.nombre_unidad_medida,
			producto: item.producto,
			unidad_medida: item.unidad_medida,
			precio_compra: item.precio_compra,
			precio_venta: item.precio_venta,
			estado: item.estado,
			cantidad_unidades: item.cantidad_unidad_medida,
		}
	},
	async fillcodigoBarraMod(item, item2){
		this.codigoBarraMod = {
			id: item.id,
			nombre_producto: item2,
			codigo_barra: item.codigo_barra,
			producto: item.producto,
			estado: item.estado,

		}
	},
	async modificarCodigoBarra(){
		try{
			const formData = new FormData();
			formData.append("codigo_barra", this.codigoBarraMod.codigo_barra);
			formData.append("producto", this.codigoBarraMod.producto);
			formData.append("estado", this.codigoBarraMod.estado);
			await fetch(this.URL_TENANT+'/api/producto_barra/'+this.codigoBarraMod.id+'/',{
			method: 'PUT',
			headers: {
				'X-CSRFToken': Cookies.get('csrftoken'),
			},body: formData})
			notificaciones.round_success_noti($.msg='Código de Barra '+this.codigoBarraMod.nombre_producto+' actualizada Exitosamente');
			for(var i=0;i<this.productoModificar[0].codigos_barra_producto.length;i++){
				if(this.productoModificar[0].codigos_barra_producto[i].id == this.codigoBarraMod.id){
					this.productoModificar[0].codigos_barra_producto[i].codigo_barra = this.codigoBarraMod.codigo_barra;
				}
			}
		}catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
		}
	},
	async modificarUnidadMedida(){
		var nombre_unidad_medida_nueva = '';
		var cantidad_unidades_nueva = '';
		if(this.modUnidadProd){
			this.precioCompraUnidadMedida = this.unidadMedidaMod.precio_compra;
			this.precioVentaUnidadMedida = this.unidadMedidaMod.precio_venta;
			this.unidadMedidaMod.unidad_medida = await this.agregarUnidadesMedida();
			this.modUnidadProd = false;
			nombre_unidad_medida_nueva = this.nombreUnidadMedida + ' / ' + this.cantidadUnidadMedida;
			cantidad_unidades_nueva = this.cantidadUnidadMedida;
		}else{
			nombre_unidad_medida_nueva = this.unidadMedidaMod.nombre_unidad_medida;
			cantidad_unidades_nueva = this.unidadMedidaMod.cantidad_unidades;
		}
		try{
			const formData = new FormData();
			formData.append("precio_compra", this.unidadMedidaMod.precio_compra);
			formData.append("precio_venta", this.unidadMedidaMod.precio_venta);
			formData.append("unidad_medida", this.unidadMedidaMod.unidad_medida);
			formData.append("producto", this.unidadMedidaMod.producto);
			formData.append("estado", this.unidadMedidaMod.estado);
			await fetch(this.URL_TENANT+'/api/producto_unidades_medida/'+this.unidadMedidaMod.id+'/',{
			method: 'PUT',
			headers: {
				'X-CSRFToken': Cookies.get('csrftoken'),
			},body: formData})
			notificaciones.round_success_noti($.msg='Unidad de Medida '+this.unidadMedidaMod.nombre_producto+' actualizada Exitosamente');
			for(var i=0;i<this.productoModificar[0].unidades_medida_producto.length;i++){
				if(this.productoModificar[0].unidades_medida_producto[i].id == this.unidadMedidaMod.id){
					this.productoModificar[0].unidades_medida_producto[i].nombre_unidad_medida = nombre_unidad_medida_nueva;
					this.productoModificar[0].unidades_medida_producto[i].cantidad_unidad_medida = cantidad_unidades_nueva;
					this.productoModificar[0].unidades_medida_producto[i].precio_compra = this.unidadMedidaMod.precio_compra;
					this.productoModificar[0].unidades_medida_producto[i].precio_venta = this.unidadMedidaMod.precio_venta;
					this.productoModificar[0].unidades_medida_producto[i].estado = this.unidadMedidaMod.estado;
				}
			}
		}catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
		}
	},
	async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
	async agregarProveedores(){
		if (this.selectedEstadoProveedor != '' && this.selectedProveedor != ''){
			try{ // Agrega Proveedores
				console.log("DENTRO DE PROVEEDORES");
					const formDataProveedores = new FormData();
					formDataProveedores.append("proveedor", this.selectedProveedor[0]);
					formDataProveedores.append("producto", this.productoModificar[0].id);
					formDataProveedores.append("estado", this.selectedEstadoProveedor[0]);
					await fetch(this.URL_TENANT+'/api/producto_proveedor/',{
					method: 'post',
					headers: {
						'X-CSRFToken': Cookies.get('csrftoken'),
					},body: formDataProveedores})
					.then(response => response.json()).then(json => this.nuevoProveedor = json).catch(err => console.log(err));
					notificaciones.round_success_noti($.msg='Se Agregó Proveedor '+this.nuevoProveedor.nombre_proveedor+' al producto.');
			}catch(error){
				console.log(error);
				notificaciones.round_error_noti($.msg='Error, algo salió mal');
			}// Finaliza Agregar Proveedores
			const obj3 = {
				estado: this.nuevoProveedor.estado,
				id: this.nuevoProveedor.id,
				nombre_proveedor: this.nuevoProveedor.nombre_proveedor,
				producto: this.nuevoProveedor.producto,
				proveedor: this.nuevoProveedor.proveedor,
			}
			this.productoModificar[0].proveedores_producto.push(obj3);
			this.selectedEstadoProveedor = '';
			this.selectedProveedor = '';
		}else{
			notificaciones.round_error_noti($.msg="Debe Llenar Todos los Campos");
		}
	},
	async agregarUnidadesMedida(){
        this.agregando = true;

		if (this.nombreUnidadMedida != '' && this.cantidadUnidadMedida != 0 && this.precioCompraUnidadMedida != 0 && this.precioVentaUnidadMedida != 0){
			try{ // Agrega Unidades de Medida
				console.log("DENTRO DE UNIDADES DE MEDIDA");
				//Ingreso de Unidad de Medida, verifica si existe, sino lo crea.
				await fetch(this.URL_TENANT+'/api/unidades_medida/')
				.then(response => response.json()).then(json => this.unidadesMedidaVerificar = json).catch(err => console.log(err));
				console.log(this.idUnidadMedida);
					this.idUnidadMedida= null;
					for(var i in this.unidadesMedidaVerificar){
						console.log("FOR VERIFICAR");
						if(this.nombreUnidadMedida == this.unidadesMedidaVerificar[i].nombre && this.cantidadUnidadMedida == this.unidadesMedidaVerificar[i].cantidad_unidades){
							this.idUnidadMedida =this.unidadesMedidaVerificar[i].id;
							console.log("DENTRO DEL IF");
							break;
						}
					}
					if(this.idUnidadMedida === null){
						console.log("AGREGANDO NUEVO");
						const formDataUnidad = new FormData();
						formDataUnidad.append("nombre", this.nombreUnidadMedida);
						formDataUnidad.append("cantidad_unidades", this.cantidadUnidadMedida);
						await fetch(this.URL_TENANT+'/api/unidades_medida/',{
						method: 'post',
						headers: {
							'X-CSRFToken': Cookies.get('csrftoken'),
						},body: formDataUnidad})
						.then(response => response.json()).then(json => this.idUnidadMedida = json.id).catch(err => console.log(err));
					}
					console.log("UNIDAD  DE MEDIDA A INGRESAR -----------------------");
					console.log(this.idUnidadMedida);
					if(this.modUnidadProd){
						return this.idUnidadMedida;
					}
					const formDataUnidades = new FormData();
					formDataUnidades.append("unidad_medida", this.idUnidadMedida);
					formDataUnidades.append("producto", this.productoModificar[0].id);
					formDataUnidades.append("estado", 'A');
					formDataUnidades.append("precio_compra", this.precioCompraUnidadMedida);
					formDataUnidades.append("precio_venta", this.precioVentaUnidadMedida);
					formDataUnidades.append("precio_venta", this.precioVentaUnidadMedida);
					if(this.codigobarraUnidadMedida.length >0){ this.barraUnidad = this.codigobarraUnidadMedida[0]}else{this.barraUnidad = ''}
					formDataUnidades.append("codigo_barra", this.barraUnidad);
					await fetch(this.URL_TENANT+'/api/producto_unidades_medida/',{
					method: 'post',
					headers: {
						'X-CSRFToken': Cookies.get('csrftoken'),
					},body: formDataUnidades})
					.then(response => response.json()).then(json => this.nuevaUnidad = json).catch(err => console.log(err));
					notificaciones.round_success_noti($.msg='Se Agregó la Unidad de Medida '+this.nuevaUnidad.nombre_unidad_medida+'/'+this.nuevaUnidad.cantidad_unidad_medida+' al producto.');
					this.codigobarraUnidadMedida = '';
		}catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
			this.agregando = false;
		}// Finaliza Agregar Unidades de Medida
			const obj = {
				cantidad_unidad_medida: this.nuevaUnidad.cantidad_unidad_medida,
				estado: this.nuevaUnidad.estado,
				id: this.nuevaUnidad.id,
				nombre_unidad_medida: this.nuevaUnidad.nombre_unidad_medida,
				precio_compra: this.nuevaUnidad.precio_compra,
				precio_venta: this.nuevaUnidad.precio_venta,
				producto: this.nuevaUnidad.producto,
				unidad_medida: this.nuevaUnidad.unidad_medida,
			}
			this.productoModificar[0].unidades_medida_producto.push(obj);
			this.nombreUnidadMedida = '';
			this.cantidadUnidadMedida = 0;
			this.precioCompraUnidadMedida = '';
			this.precioVentaUnidadMedida = '';
		}else{
			notificaciones.round_error_noti($.msg="Debe Llenar Todos los Campos");
		}
        this.agregando = false;
	},
	async agregarCodigosBarra(){
        this.agregando = true;

		if (this.codigoBarra != '' && this.selectedPrioridad != ''){
			try{
				const formDataBarra = new FormData();
					if(this.selectedPrioridad[0] === 'Principal'){
						this.estadoBarraIngresar = 'P';
					}
					if(this.selectedPrioridad[0] === 'Secundario'){
						this.estadoBarraIngresar = 'S';
					}
					console.log("FORM DATA BARRA");
					console.log(this.codigoBarra);
					console.log(this.productoModificar[0].id);
					console.log(this.estadoBarraIngresar);
					formDataBarra.append("codigo_barra", this.codigoBarra);
					formDataBarra.append("producto", this.productoModificar[0].id);
					formDataBarra.append("estado", this.estadoBarraIngresar);
					await fetch(this.URL_TENANT+'/api/producto_barra/',{
					method: 'post',
					headers: {
						'X-CSRFToken': Cookies.get('csrftoken'),
					},body: formDataBarra})
					.then(response => response.json()).then(json => this.nuevaBarra = json).catch(err => console.log(err));
					notificaciones.round_success_noti($.msg='Se Agregó la Barra '+this.nuevaBarra.codigo_barra+' al producto.');
					}catch(error){
						console.log(error);
						notificaciones.round_error_noti($.msg='Error, algo salió mal');
					}// Finaliza Agregar Codigos de Barra
			const obj2 = {
				codigo_barra: this.nuevaBarra.codigo_barra,
				estado: this.nuevaBarra.estado,
				id: this.nuevaBarra.id,
				producto: this.nuevaBarra.producto,
			}
			this.productoModificar[0].codigos_barra_producto.push(obj2);
			this.codigoBarra = '';
			this.selectedPrioridad = '';
		}else{
			notificaciones.round_error_noti($.msg="Debe Llenar Todos los Campos");
		}
        this.agregando = false;

	},
	async eliminarCodigosBarra(codigo_barra= 0){
		console.log("ELIMINAR BARRA"+codigo_barra);

	},
	async cambiarEstadoProductoModificar(estado = ''){
		if (estado == 'A'){
			this.productoModificar[0].estado = 'I';
		}
		if (estado == 'I'){
			this.productoModificar[0].estado = 'A';
		}
	},
	async closeModal(){
		console.log("Se cerro");
	},
	async modificarGlobalProductos(id, campo, valor, numero, verbose){
		try{
        const formData = new FormData();
        formData.append("id", id);
        formData.append("campo", campo);
        formData.append("valor", valor);
        formData.append("numero", numero);
        formData.append("verbose", verbose);
        console.log("DATOS USER");
        await fetch(this.URL_TENANT+'/api/global_producto/'+id+'/',{
        method: 'PUT',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
        },body: formData})
		this.llenarListadoTabla();

    }catch(error){
        console.log(error);
        notificaciones.round_error_noti($.msg='Error, algo salió mal');
    }
	},
	async cambiarOrdenArray(old_index, new_index){
		console.log("OLD INDEX");
		console.log(old_index);
		console.log("NEW INDEX");
		console.log(new_index);
		if (new_index >= this.globalProducto.length) {
        var k = new_index - this.globalProducto.length + 1;
        while (k--) {
            this.globalProducto.push(undefined);
        }
    }
    this.globalProducto.splice(new_index, 0, this.globalProducto.splice(old_index, 1)[0]);
			this.globalProducto[old_index].numero = old_index;
			this.globalProducto[new_index].numero = new_index;
			this.modificarGlobalProductos(
				this.globalProducto[old_index].id,
				this.globalProducto[old_index].campo,
				this.globalProducto[old_index].valor,
				this.globalProducto[old_index].numero,
				this.globalProducto[old_index].verbose,
			);
			this.modificarGlobalProductos(
				this.globalProducto[new_index].id,
				this.globalProducto[new_index].campo,
				this.globalProducto[new_index].valor,
				this.globalProducto[new_index].numero,
				this.globalProducto[new_index].verbose,
			);
			this.llenarListadoTabla();
    return this.globalProducto;
	},
	async llenarListadoTabla(){
		this.listadoTabla = [];
		//contar = 0;
		console.log("LISTADO TABLA");
		for(var u in this.globalProducto){
			console.log(u.valor);
			if (this.globalProducto[u].valor === true){
				console.log("ENTRO");
				this.listadoTabla.push({
					orden: this.globalProducto[u].numero,
					nombre: this.globalProducto[u].campo,
				});
				//contar = contar+1;
			}
		}
		this.listadoTabla.sort(function(a, b) {
			var keyA = new Date(a.orden),
				keyB = new Date(b.orden);
			// Compare the 2 dates
			if (keyA < keyB) return -1;
			if (keyA > keyB) return 1;
			return 0;
			});
	},
	async productoEliminar(idProducto, nombreProducto) {
		this.idProductoEliminar = idProducto;
		this.nombreProductoEliminar = nombreProducto;
	},
	async cambiarEstado(idOption, idProducto) {
		console.log("ID PRODUCTO");
		console.log(idProducto);
		this.option = idOption;
		if(idOption == 3){
		this.productoModificar = [];
		console.log("option 3");
		for(var x in this.productoDataFilter){
			console.log("CICLO");
			console.log(this.productoDataFilter[x].id);
			if(this.productoDataFilter[x].id==idProducto){
				console.log("if");
				this.productoModificar.push(this.productoDataFilter[x]);
				console.log("SELECTED CATEGORIA");
				if(this.productoModificar[0].categoria == undefined){
					this.selectedCategoria = '';
				}else{
					this.selectedCategoria = [this.productoModificar[0].categoria];
				}
				}
			}
		}
		this.getProductosData();
	},
	async modificarProducto() {
		try{
			if(this.selectedCategoria[0] === undefined){this.catIng = ''}else{this.catIng = this.selectedCategoria[0]}
			const formData = new FormData();
			formData.append("descripcion", this.productoModificar[0].descripcion);
			formData.append("codigo", this.productoModificar[0].codigo);
			formData.append("nombre", this.productoModificar[0].nombre);
			formData.append("marca", this.productoModificar[0].marca);
			//formData.append("inventario", 0);
			formData.append("permitir_venta", this.productoModificar[0].permitir_venta);
			formData.append("permitir_compra", this.productoModificar[0].permitir_compra);
			formData.append("observaciones", this.productoModificar[0].observaciones);
			formData.append("categoria", this.catIng);
			formData.append("estado", this.productoModificar[0].estado);
			formData.append("categoria_nombre", '');
			formData.append("_imagenes", []);
			for (var index = 0; index < $('#imagenesNuevo')[0].files.length; index++) {
				formData.append("imagenesNuevo", $('#imagenesNuevo')[0].files[index]);
			}
			console.log("DATOS USER");
			await fetch(this.URL_TENANT+'/api/producto/'+this.productoModificar[0].id+'/',{
			method: 'put',
			headers: {
				'X-CSRFToken': Cookies.get('csrftoken'),
			},body: formData})
			notificaciones.round_success_noti($.msg='Producto '+this.productoModificar[0].nombre+' Modificado Exitosamente');
			this.getProductosSinPagData();
			}
		catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
		} // Fin Agregar Producto
    },
	async eliminarProducto() {
		const requestOptions = {
			method: 'DELETE',
			headers: {
			'X-CSRFToken': Cookies.get('csrftoken'),
			}
		};
		await fetch(this.URL_TENANT+'/api/producto/'+this.idProductoEliminar+'',  requestOptions);
		notificaciones.round_success_noti($.msg='Producto Eliminado');
		this.getProductosData();
	},
	async getProductosData() {
        const response = await fetch(this.url);
        this.productosData = await response.json();
    },
	async getProductosSinPagData() {
        const response = await fetch(this.URL_TENANT+'/api/producto_busqueda/');
        this.productosSinPagData = await response.json();
    },
	async getCategoriasData() {
        const response = await fetch(this.URL_TENANT+'/api/categoria/');
        this.categoriasData = await response.json();
    },
	async getProveedoresData() {
        const response = await fetch(this.URL_TENANT+'/api/proveedor/');
        this.proveedoresData = await response.json();
    },
	async getGlobalProducto() {
		const response = await fetch(this.URL_TENANT+'/api/global_producto/');
        this.globalProducto = await response.json();
		this.llenarListadoTabla();
	},
    },
watch: {
	selectedCategoria: function () {
		console.log("CATEGORIA");
		console.log(this.selectedCategoria);
		if(this.selectedCategoria.length > 1){
			this.selectedCategoria.shift();
		}
		this.productoModificar[0].categoria = this.selectedCategoria[0];
	},
	selectedPrioridad: function () {
		if(this.selectedPrioridad.length > 1){
			this.selectedPrioridad.shift();
		}
	},
	estadoModificar: function (){
		if(this.estadoModificar=="A" || this.estadoModificar == true){
			this.estadoModificar = true;
			this.estadoMostrar = "Activo";
		}
		else if(this.estadoModificar == "I" || this.estadoModificar == false){
			this.estadoModificar = false;
			this.estadoMostrar = "Inactivo";
		}
	},
	permitirCompraModificar: function (){
		if(this.permitirCompraModificar == true){
			this.permitirCompraMostrar = "Permitir Compra";
		}
		else if(this.permitirCompraModificar == false){
			this.permitirCompraMostrar = "No Permitir Compra";
		}
	},
	permitirVentaModificar: function (){
		if(this.permitirVentaModificar == true){
			this.permitirVentaMostrar = "Permitir Venta";
		}
		else if(this.permitirVentaModificar == false){
			this.permitirVentaMostrar = "No Permitir Venta";
		}
	},
	url: function () {
            this.getProductosData();
        },
},

created() {
      // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
	this.getGlobalProducto();
    this.getProductosData();
	this.getProductosSinPagData();
    this.getCategoriasData();
	this.getProveedoresData();


	},
}

</script>
