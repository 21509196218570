
<template>
    <div class="row mb-3">
        <div class="col-sm-2">
            <h6 class="mb-0">Fecha</h6>
        </div>
        <div class="col-sm-10">
            <Datepicker
                v-model="factura.fecha_hora_emision"
                dark placeholder="Selecciona una Fecha"
                autoApply
                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                :enableTimePicker="false"
                monthNameFormat="long"
                :format="format"
                modelType="dd-MM-yyyy"
                locale="es">
            </Datepicker>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-sm-2">
            <h6 class="mb-0">NIT</h6>
        </div>
        <div class="col-sm-10">
            <input ref="inputInModal" type="text" id="nit"  class="form-control" v-model="factura.nit" />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-sm-2">
            <h6 class="mb-0">Nombre</h6>
        </div>
        <div class="col-sm-10">
            <input type="text" id="nombre"  class="form-control"  v-model="factura.nombre" />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-sm-2">
            <h6 class="mb-0">Dirección</h6>
        </div>
        <div class="col-sm-10">
            <input type="text" id="direccion"  class="form-control" v-model="factura.direccion"  />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-sm-12">
            <h6 class="mb-0">Detalle de Venta:</h6>
        </div>
    </div>

    <div class="row mb-3">

        <div class="col-sm-12">

        <table class="table mb-0">
            <thead class="table-light">
                <tr>
                    <th><h6>Producto</h6></th>
                    <th><h6>Cantidad</h6></th>
                    <th><h6>Unidad Medida</h6></th>
                    <th><h6>Precio</h6></th>
                    <th><h6>Sub Total</h6></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item2 in factura.detalle_venta" :key="item2.id">
                    <td><h6>{{item2.nombre_producto}}</h6></td>
                    <td><h6>{{item2.cantidad}}</h6></td>
                    <td><h6>{{item2.nombre_unidad_medida}}</h6></td>
                    <td><h6>{{ $filters.currencyGTQ(item2.precio) }}</h6></td>
                    <td><h6>{{ $filters.currencyGTQ(item2.cantidad * item2.precio)}}</h6></td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
</template>
<style>
.btn-altura {
    line-height: 1.5; /* Ajusta este valor según tus necesidades */
    padding: 10px 20px;
}
</style>
<script>
    import  Cookies from 'js-cookie';
    import $ from 'jquery';
    import { ref } from 'vue';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    import '@vuepic/vue-datepicker/dist/main.css';
    import Datepicker from '@vuepic/vue-datepicker';
    console.log(Cookies.get());
    export default {
        props: ['datos_venta'],
        name: 'facturar_component',
        components:{
            Datepicker,
        },
    setup() {
        const date = ref(new Date());
            function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            //const today_date = ref(new Date().toISOString().split('T')[0]);
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
            //var today_dat = new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
            //console.log("TIEMPO: "+ formatDate(today_date));
            var today_date = formatDate(today_is);
            //
            return {
                date,
                format,
                first_time,
                last_time,
                startTime,
                today_date,
            }
    },
    data() {
        return{
            //Globales:
            tipo_factura: '',
            facturando: false,
            factura_creada: false,
            URL_TENANT: '',
            factura_datos: {},
            factura: {
                fecha_hora_emision: this.today_date,
                nit: '',
                nombre: '',
                direccion: '',
                // Otros campos según sea necesario
            },
            }
        },

    computed: {
    },
    watch:{
        'factura.fecha_hora_emision': function() {
            this.validarFecha();
        },
        datos_venta: {
        immediate: true,
        handler(newData) {
            this.factura = { ...newData };
            this.factura.fecha_hora_emision = this.today_date;
            this.factura_creada = false;
            this.facturando = false;
            this.getFacturaDatos();
            }
        }
    },
    methods: {
        validarFecha() {
            console.log("VALIDADNDO FECHA");
            const [day, month, year] = this.factura.fecha_hora_emision.split('-');
            const selectedDate = new Date(year, month - 1, day);

            const [dayToday, monthToday, yearToday] = this.today_date.split('-');
            const currentDate = new Date(yearToday, monthToday - 1, dayToday);

            const differenceInDays = Math.ceil((selectedDate - currentDate) / (1000 * 60 * 60 * 24));
            console.log("selectedDate" +selectedDate);
            console.log("currentDate" +currentDate);
            console.log("differenceInDays" +differenceInDays);
            if (differenceInDays < -5) {
                // La fecha seleccionada es menor a 5 días de la fecha actual
                this.factura.fecha_hora_emision = this.today_date; // Restablece a la fecha actual
                notificaciones.round_error_noti($.msg='No puedes seleccionar una fecha menor a 5 días de la fecha actual.');
            }

        },
        // Factura Datos
        async getFacturaDatos() {
            const response = await fetch(this.URL_TENANT+'/api/factura_datos/1/');
            this.factura_datos = await response.json();
        },
        //Crear Factura:
        parsearFecha(fechaStr) {
            const partes = fechaStr.split('-');
            return new Date(partes[2], partes[1] - 1, partes[0]);
        },
        formatearFecha(fecha) {
            // Obtener la hora actual en UTC
            const ahoraUTC = new Date();

            // Ajustar la fecha a la hora UTC
            fecha.setUTCFullYear(ahoraUTC.getUTCFullYear(), ahoraUTC.getUTCMonth(), ahoraUTC.getUTCDate());
            fecha.setUTCHours(ahoraUTC.getUTCHours(), ahoraUTC.getUTCMinutes(), ahoraUTC.getUTCSeconds());

            // Ajustar la fecha a la zona horaria UTC-06:00 restando 6 horas
            fecha.setUTCHours(fecha.getUTCHours() - 6);

            // Formatear la fecha al formato deseado
            return fecha.toISOString().split('.')[0] + '-06:00';
        },
        async crearFactura(){
            if(this.factura.total >= 2500 && this.factura.nit =='CF'){
                notificaciones.round_error_noti($.msg='Debe ingresar NIT y Nombre Válidos para facturas mayores a 2500');
                return;
            }
            this.facturando = true;

            try{
                const formData = new FormData();

                const fechaOriginal = this.factura.fecha_hora_emision;
				const ahora = new Date();
				const horas = ahora.getHours().toString().padStart(2, '0');
				const minutos = ahora.getMinutes().toString().padStart(2, '0');
				const fechaHora = `${fechaOriginal} ${horas}:${minutos}`;
                formData.append("fecha_hora_emision", fechaHora);
                formData.append("venta", this.factura.id);
                
                if(this.datos_venta.tipo_pago == "CREDITO"){
                    formData.append("tipo", "FCAM");
                }else{
                    formData.append("tipo", "FACT");
                }
                formData.append("id_receptor", this.factura.nit);
                formData.append("nombre_receptor", this.factura.nombre);
                formData.append("direccion", this.factura.direccion);
                formData.append("codigo_postal", 0);
                formData.append("municipio", this.factura_datos.municipio);
                formData.append("departamento", this.factura_datos.departamento);
                formData.append("pais", this.factura_datos.pais);
                formData.append("tipo_frase", this.factura_datos.tipo_frase);
                formData.append("codigo_escenario", this.factura_datos.codigo_escenario);
                formData.append("nombre_corto", "IVA"); //Modificar luego al cambiar entidades
                const response = await fetch(this.URL_TENANT+'/api/factura/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                if(response.ok){
                    const data = await response.json();
                    this.id_factura = data.id;
                }

                for(var i=0;i<this.factura.detalle_venta.length;i++){
                    var total = this.factura.detalle_venta[i].precio * this.factura.detalle_venta[i].cantidad;
                    const formDataDet = new FormData();
                    formDataDet.append("factura", this.id_factura);
                    formDataDet.append("numero_linea", i+1);
                    formDataDet.append("bien_servicio", "B");
                    formDataDet.append("cantidad", this.factura.detalle_venta[i].cantidad);
                    formDataDet.append("unidad_medida", "UND");
                    formDataDet.append("descripcion_producto", this.factura.detalle_venta[i].nombre_producto);
                    formDataDet.append("precio_unitario", this.factura.detalle_venta[i].precio);
                    formDataDet.append("precio", total);
                    formDataDet.append("total", total);
                    formDataDet.append("im", "IM");
                    formDataDet.append("cantidad_impuestos", 1);
                    formDataDet.append("nombre_corto_impuesto", "IVA"); //Modificar luego al cambiar entidades
                    formDataDet.append("unidad_gravable", 1);
                    formDataDet.append("monto_gravable", 0);
                    formDataDet.append("monto_impuesto", 0);
                    await fetch(this.URL_TENANT+'/api/factura_detalle/',{
                        method: 'POST',
                        headers: {
                            'X-CSRFToken': Cookies.get('csrftoken'),
                        },body: formDataDet})
                }
                try {
                    const certificarResponse = await fetch(this.URL_TENANT + '/api/certificar_factura/' + this.id_factura + '/' + this.datos_venta.tipo_pago + '/', {
                        method: 'POST',
                        headers: {
                            'X-CSRFToken': Cookies.get('csrftoken'),
                        }
                    });
                    if (!certificarResponse.ok) {
                        // Manejamos el error si el estado no es 200
                        console.error(`Error al certificar factura: ${certificarResponse.status} ${certificarResponse.statusText}`);
                        notificaciones.round_error_noti($.msg='Error, No se pudo certificar la factura.');
                        
                    }else{
                        notificaciones.round_success_noti($.msg='Factura Creada Exitosamente');
                        document.getElementById('close').click();
                        this.factura_creada = true;
                        this.facturando = false;
                        this.$emit('factura-creada');
                    }
                } catch (certificarError) {
                    // Capturamos errores en el fetch
                    console.error("Error al intentar certificar la factura:", certificarError);
                }
                
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
                
            },
        },
mounted() {
                this.$refs.inputInModal.focus();
                this.$refs.inputInModal.select();
    },
beforeUnmount() {
},
created() {
        // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getFacturaDatos();
    },
}
    </script>