<template>
      <Header/>
      <Nav/>
      <DetalleFactura/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import DetalleFactura from '../../components/facturas/detalle_factura.vue'



export default {
  name: 'DetalleFacturaComponent',

  components: {
    Header,
    Nav,
    DetalleFactura,
  },

  data: () => ({
    //
  }),
}
</script>
