
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Reportes</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bxs-report'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Utilidades del Producto</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <!-- Columna para búsqueda -->
                                <div class="col-md-3 mb-3">
                                    <label class="form-label mb-2"><h6>Buscar Producto (o deja en blanco para mostrar todos los productos):</h6></label>
                                    <input type="text" class="form-control" v-model="filtro.codigo" placeholder="Introduce el Código del Producto">
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label class="form-label mb-2"><h6>Filtrar por Número de Venta:</h6></label>
                                    <input type="text" class="form-control" v-model="filtro.venta" placeholder="Introduce el Número de Venta">
                                </div>
                                <!-- Columna para Fecha Inicio -->
                                <div class="col-md-2 mb-3">
                                    <label class="form-label mb-2"><h6>Fecha Fin:</h6></label>
                                    <!-- Añade aquí tu Datepicker -->
                                    <Datepicker
                                        v-model="filtro.fecha_inicio"
                                        dark placeholder="Fecha Inicio"
                                        autoApply
                                        :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                        :enableTimePicker="false"
                                        monthNameFormat="long"
                                        :format="format"
                                        modelType="dd-MM-yyyy"
                                        locale="es">
                                    </Datepicker>
                                </div>

                                <!-- Columna para Fecha Fin -->
                                <div class="col-md-2 mb-3">
                                    <label class="form-label mb-2"><h6>Fecha Fin:</h6></label>
                                    <!-- Añade aquí tu Datepicker -->
                                    <Datepicker
                                        v-model="filtro.fecha_fin"
                                        dark placeholder="Fecha Fin"
                                        autoApply
                                        :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                        :enableTimePicker="false"
                                        monthNameFormat="long"
                                        :format="format"
                                        modelType="dd-MM-yyyy"
                                        locale="es">
                                    </Datepicker>
                                </div>
                                <!-- Columna para Buscar -->
                                <div class="col-md-2 mb-3">
                                    <button @click="imprimirReporte()" class="btn btn-light w-100 mb-2"><i  class='bx bx-printer'></i> Imprimir</button>
                                    <button @click="getProductosBusquedaData()" class="btn btn-success w-100">
                                        <loading v-show="mostrando"></loading>
                                        Mostrar
                                    </button>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>Fecha</h6></th>
                                            <th><h6>Número de Venta</h6></th>
                                            <th><h6>Código</h6></th>
                                            <th><h6>Producto</h6></th>
                                            <th><h6>Cantidad</h6></th>
                                            <th><h6>Precio</h6></th>
                                            <th><h6>Total</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="itemProd in productosBusqueda" :key="itemProd.id">
                                            <td><h6>
                                                <label>{{itemProd.venta_fecha_creacion}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.venta_numero}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.codigo}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.nombre}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.cantidad_vendida}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{  $filters.currencyGTQ(itemProd.precio_vendido) }}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{  $filters.currencyGTQ(itemProd.subtotal) }}</label>
                                            </h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    console.log(Cookies.get());
    import { ref } from 'vue';
    import '@vuepic/vue-datepicker/dist/main.css';
    import Datepicker from '@vuepic/vue-datepicker';
    import loading from "../utils/loading.vue"

    export default {
        name: 'buscar_producto_component',
        components:{
            Datepicker,
            loading,

        },
        setup() {
        const date = ref(new Date());
            function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            //const today_date = ref(new Date().toISOString().split('T')[0]);
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
            //var today_dat = new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
            //console.log("TIEMPO: "+ formatDate(today_date));
            var today_date = formatDate(today_is);
            //
            return {
                date,
                format,
                first_time,
                last_time,
                startTime,
                today_date,
            }
    },
    data() {
        return{
            URL_TENANT: '',
            productosBusqueda: [],
            mostrando: false,
            busqueda: '',
            filtro: {
                codigo: '',
                venta: '',
                fecha_inicio: this.today_date,
                fecha_fin: this.today_date
            }
            }
        },
    watch: {
        },
    computed: {
    },
    methods: {
        imprimirReporte() {
            var codigo = '';
            var venta = '';
            if(this.filtro.codigo == ''){
                codigo = 'TODOS';
            }else{
                codigo = this.filtro.codigo;
            }
            if(this.filtro.venta == ''){
                venta = 'TODOS';
            }else{
                venta = this.filtro.venta;
            }
            const url = this.URL_TENANT+'/reportes/reporte_impresion_utilidades_del_producto/'+codigo+'/'+this.filtro.fecha_inicio+'/'+this.filtro.fecha_fin+'/'+venta+'/';
            window.open(url, '_blank'); // Esto abrirá la URL en una nueva pestaña
        },
        async getProductosBusquedaData() {
            this.mostrando = true;

            var codigo = '';
            var venta = '';
            if(this.filtro.codigo == ''){
                codigo = 'TODOS';
            }else{
                codigo = this.filtro.codigo;
            }
            if(this.filtro.venta == ''){
                venta = 'TODOS';
            }else{
                venta = this.filtro.venta;
            }
        const response = await fetch(this.URL_TENANT+'/api/reporte_utilidades_del_producto/'+codigo+'/'+this.filtro.fecha_inicio+'/'+this.filtro.fecha_fin+'/'+venta+'/');
        this.productosBusqueda = await response.json();
        this.mostrando = false;

    },
        },
        mounted() {
    },
    created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        },
    }
    </script>